import { XMarkIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Card from 'src/components/Card';
import IconDropdown from 'src/components/input/IconDropdown';
import TopBar from 'src/components/nav/TopBar';
import OrderNotes from 'src/components/Orders/common/OrderNotes.tsx';
import { getDeliveryDateString } from 'src/components/Orders/OrderRowItem.tsx';
import OrderTimeline from 'src/components/Orders/OrderTimeline.tsx';
import Spinner from 'src/components/Spinner';
import useS3URLs from 'src/hooks/data/files/useS3.ts';
import useOrder from 'src/hooks/data/orders/useOrder.ts';
import { Order, OrderStatus } from 'src/hooks/data/orders/useOrders.ts';
import classNames from 'src/tools/classNames';
import AddressHelper from 'src/utils/addressHelper.ts';
import {
  CalendarIcon,
  CheckCircleIcon
} from '@heroicons/react/24/solid';
import {
  TableCellsIcon,
  CurrencyDollarIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import S3, { S3File } from "src/tools/S3/s3.ts";
import Modal from 'src/components/Modal';
import UserManager from 'src/tools/UserManager';
import useCurrentUser from 'src/hooks/data/users/useCurrentUser.ts';
import { OrderStatusLabels } from 'src/hooks/data/orders/useOrders.ts';
import Button from 'src/components/input/Button';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import AddNoteModal from 'src/components/Orders/modals/AddNoteModal.tsx';

type Props = {}

type OrderDetailsContextType = {
  order: Order | null;
  setOrder: React.Dispatch<React.SetStateAction<Order | null>>;
  bomLink: string | null;
  quoteLink: string | null;
  pickTicketLink: string | null;
}

const OrderDetailsContext = createContext<OrderDetailsContextType>({
  order: null,
  setOrder: () => { },
  bomLink: null,
  quoteLink: null,
  pickTicketLink: null,
})

/**
 * Page for viewing details of a single order. Currently displays:
 * - Metadata like dates, address, contact, PO/SO#, etc.
 * - Notes
 * - Activity timeline
 *
 * Still needs:
 * - Attachments
 * - Photos
 * - Verification 
 * - Issues
 */
export default function OrderDetailsPage({ }: Props) {
  const params = useParams();

  const orderId = params?.id;
  const orderHook = useOrder(orderId)
  const [order, setOrder] = useState<Order | null>(orderHook);

  const bomLinkArr = useS3URLs(order?.files.billOfMaterials.at(-1)?.filePath ? [order.files.billOfMaterials[0].filePath] : []);
  const quoteLinkArr = useS3URLs(order?.quote?.file?.filePath ? [order.quote.file.filePath] : []);
  const pickTicketLinkArr = useS3URLs(order?.files.pickTickets.at(-1)?.filePath ? [order.files.pickTickets[0]?.filePath] : []);

  const bomLink = bomLinkArr?.length ? bomLinkArr[0].getObjectSignedUrl : null;
  const quoteLink = quoteLinkArr?.length ? quoteLinkArr[0].getObjectSignedUrl : null;
  const pickTicketLink = pickTicketLinkArr?.length ? pickTicketLinkArr[0].getObjectSignedUrl : null;

  const [packagingImages, setPackagingImages] = useState<S3File[]>([]);
  const [deliveryImages, setDeliveryImages] = useState<S3File[]>([]);
  const [installerVerificationImages, setInstallerVerificationImages] = useState<S3File[]>([]);
  const [otherImages, setOtherImages] = useState<S3File[]>([]);

  const [additionalAttachments, setAdditionalAttachments] = useState<S3File[]>([]);

  // Update order state when hook updates
  useEffect(() => {
    setOrder(orderHook);
  }, [orderHook])


  // retrieve all images and attachments for the order
  useEffect(() => {
    if (order) {
      S3.getFolderContents(`packaging_images/${order?.installerId}/${order?.marketId}/${order?._id}`)
        .then((res) => {
          setPackagingImages(res)
        })
        .catch(err => {
          console.error(err)
        })

      S3.getFolderContents(`delivery_images/${order?.installerId}/${order?.marketId}/${order?._id}`)
        .then((res) => {
          setDeliveryImages(res)
        })
        .catch(err => {
          console.error(err)
        })

      S3.getFolderContents(`installer_verification_images/${order?.installerId}/${order?.marketId}/${order?._id}`)
        .then((res) => {
          setInstallerVerificationImages(res)
        })
        .catch(err => {
          console.error(err)
        })

      getAdditionalAttachments()

      // TODO: restructure S3 a little bit to include an "other_images" folder
      // TODO: also add a folder for quotes
      // TODO: consider renaming "delivery_images" to "delivery_verification_images", and "packaging_images" to "packaging_verification_images" to match with the "installer_verification_images" folder
      S3.getFolderContents(`other_images/${order?.installerId}/${order?.marketId}/${order?._id}`)
        .then((res) => {
          console.log("other_images", res)
          setOtherImages(res)
        })
        .catch(err => {
          console.error(err)
        })
    }
  }, [order])


  /**
   * Get presigned URLs for additional attachments in the order.
   */
  async function getAdditionalAttachments() {
    const filePaths = order.files.additionalFiles?.map((file) =>
      file?.filePath
    )

    setAdditionalAttachments(await S3.get(filePaths))
  }

  // Navigate to order list if no order found
  if (!orderId) {
    return <Navigate to="../orders" />
  }

  // Loading state
  if (!order) {
    return <div className="flex items-center h-screen">
      <Spinner />
    </div>
  }

  const orderContextValue = {
    order,
    setOrder: setOrder,
    bomLink,
    quoteLink,
    pickTicketLink,
  }

  //  Check if there is a previous page to navigate back to
  const hasPrevPage = window.history.length > 1;

  return (
    <OrderDetailsContext.Provider value={orderContextValue}>
      <TopBar>
        <div className="flex items-center justify-between grow">
          <p>{order.name}</p>
          {
            hasPrevPage &&
            <Link
              to={-1} // Go back to previous page
              className="text-gray-500 hover:text-gray-700"
            >
              <XMarkIcon className="w-6 h-6" />
            </Link>
          }
        </div>
      </TopBar>
      <div className="flex flex-col px-8 py-6 lg:flex-row lg:flex-wrap gap-x-5 gap-y-3">
        {/* Full Width Top Section */}
        <div className='w-full'>
          <Metadata />
        </div>

        {/* 2/3 Width Left Section */}
        <div className='flex flex-col lg:grow gap-y-3'>
          <Attachments
            additionalAttachments={additionalAttachments}
            setAdditionalAttachments={setAdditionalAttachments}
          />
          <Photos
            packagingImages={packagingImages}
            deliveryImages={deliveryImages}
            installerVerificationImages={installerVerificationImages}
            otherImages={otherImages}
          />
          {/* TODO: verification & issues? */}
        </div>

        {/* 1/3 Width Left Section */}
        <div className='flex flex-col lg:basis-1/3 gap-y-3'>
          <Notes />
          <Activity />
        </div>

      </div>
    </OrderDetailsContext.Provider>
  )
}

/**
 * Displays the main large card with info about the order.
 * This includes:
 * - Delivery date
 * - Install date
 * - Material (BOM & Quote)
 * - Quote amount
 * - Address
 * - Contact info
 * - PO#, SO#, Loan #
 *
 * Still needs:
 * - TOA ID#
 * - Actions
 * - Issue count
 * - Shared with list
 */
function Metadata({ }) {
  const { order, bomLink, quoteLink, pickTicketLink } = useContext<OrderDetailsContextType>(OrderDetailsContext);

  const deliveryString = getDeliveryDateString(order)
  const installString = order.installationDate ? moment(order.installationDate).utc().format('ddd M/D') : null

  return <Card>
    {/* Top Section */}
    <div className="flex items-center pb-3 border-b border-gray-300">
      {/* TODO: TOA ID# */}
      <h1 className="text-xl font-semibold leading-7">{order.name}</h1>
      <div
        className='px-2 py-1 mx-4 border rounded-md bg-stone-50 border-stone-200'
      >
        {OrderStatusLabels[order.status]}
      </div>
      <div className="ml-auto">
        {/* TODO: implement actions */}
        {/* <IconDropdown /> */}
      </div>
    </div>

    <div className="flex flex-wrap pt-3">
      <div className="grow">
        <div className="grid grid-cols-4 col-span-2 text-sm font-medium text-gray-500">
          <Property
            label="Delivery Date"
            className='px-4 mx-4'
            icon={
              <div
                className='bg-[#d1fdff] rounded-full h-9 w-9 justify-center items-center flex'
              >
                <CalendarIcon className="w-5 h-5 text-primary-green" />
              </div>
            }
            value={deliveryString ?? "--"}
          />
          <Property
            label="Install Date"
            className='px-4 mx-4'
            value={installString}
          />
          <Property
            label="Material"
            className='px-4 mx-4 border-t border-gray-300'
            icon={
              <div
                className='bg-[#d1fdff] rounded-full h-9 w-9 justify-center items-center flex'
              >
                <TableCellsIcon className="w-5 h-5 text-primary-green" />
              </div>
            }
            value={
              <>
                <a
                  className={bomLink ? "text-primary-green hover:text-primary-green-700 cursor-pointer flex flex-row gap-2" : "text-gray-500"}
                  href={bomLink}
                  target="_blank"
                >
                  {
                    order.files.billOfMaterials.length > 0
                      ? "BOM"
                      : "--"
                  }
                  <div
                    className='overflow-hidden text-sm font-medium text-gray-600 whitespace-nowrap overflow-ellipsis'
                  >
                    {decodeURIComponent(bomLink?.split('?')[0]?.split('/')?.pop() || "")}
                  </div>
                </a>
              </>
            } />
          <Property
            label="Address"
            className='px-4 mx-4 border-t border-gray-300'
            value={
              AddressHelper.toString(order.orderAddress)
                ?.split('\n')
                .map((line, i) => <div key={i}>{line}</div>)
            }
          />
          <Property
            label="Amount"
            className='px-4 mx-4 border-t border-gray-300'
            icon={
              <div
                className='bg-[#d1fdff] rounded-full h-9 w-9 justify-center items-center flex'
              >
                <CurrencyDollarIcon className="w-5 h-5 text-primary-green" />
              </div>
            }
            value={
              order.quote?.value ? Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
              }).format(order.quote.value) : "--"

            }
          />
          <Property
            label="Contact Info"
            className='px-4 mx-4 border-t border-gray-300'
            value={<div>
              <div>{order.contact?.name || <span className="italic">No name</span>}</div>
              <div>{order.contact?.phone || <span className="italic">No phone</span>}</div>
              <div>{order.contact?.email || <span className="italic">No email</span>}</div>
            </div>}
          />
        </div>
      </div>
      <div className="basis-1/3">
        {/* TODO: shared with */}
        {/* TODO: diff name for # section? */}
        <CardTitle>Document References</CardTitle>
        <div className="grid grid-cols-2 text-sm font-medium text-gray-500 divide-y divide-gray-300">
          <Property label="PO #" value={order.poNumber ?? "--"} />
          <Property label="SO #" value={order.soNumber ?? "--"} />
          <Property label="Loan #" value={"--"} />
        </div>
      </div>
    </div>

  </Card>
}

/**
  * Displays required attachments as well as any additional attachments.
  */
function Attachments({
  // order,
  // bomLink,
  // quoteLink,
  // pickTicketLink,
  additionalAttachments,
  setAdditionalAttachments
}) {

  const { order, bomLink, quoteLink, pickTicketLink } = useContext<OrderDetailsContextType>(OrderDetailsContext);


  const bomFileName = decodeURIComponent(bomLink?.split('?')[0]?.split('/')?.pop() || "")
  const quoteFileName = decodeURIComponent(quoteLink?.split('?')[0]?.split('/')?.pop() || "")
  const pickTicketFileName = decodeURIComponent(pickTicketLink?.split('?')[0]?.split('/')?.pop() || "")

  /**
   * Deletes an additional attachment from the order, removing it from both S3 and the order object in the database.
   */
  async function deleteAdditionalAttachment(file) {
    const filePath = file.getObjectSignedUrl.split('?')[0].split('.com/').pop()

    const response = await UserManager.makeAuthenticatedRequest(
      `/api/orders/delete-attachment`,
      'DELETE',
      {
        orderId: order._id,
        filePath: filePath,
        deleteUrl: file.deleteObjectSignedUrl,
        attachmentType: "additional"
      }
    )

    if (response.data.status === "ok") {
      setAdditionalAttachments((prev) => prev.filter((f) => f.getObjectSignedUrl !== file.getObjectSignedUrl))
    }

    else {
      console.error("Error deleting attachment", response.data)
    }
  }

  return <Card>
    <CardTitle>Attachments</CardTitle>
    <div>
      {/* BOM */}
      {bomLink && (
        <a
          className="flex items-center justify-start gap-2 py-2"
          href={bomLink}
          target="_blank"
        >
          <CheckCircleIcon className="w-6 h-6 text-primary-green" />
          <div className="text-sm font-semibold text-gray-500">BOM</div>
          <div
            className="overflow-hidden text-sm font-normal text-gray-600 cursor-pointer text-nowrap overflow-ellipsis max-w-96"
          >
            {bomFileName}
          </div>
          <div
            className='text-sm font-semibold cursor-pointer text-primary-green hover:text-primary-green-700'
          >
            View
          </div>
        </a>
      )}
      {/* Quote */}
      {quoteLink && (
        <a
          className="flex items-center justify-start gap-2 py-2"
          href={quoteLink}
          target="_blank"
        >
          <CheckCircleIcon className="w-6 h-6 text-primary-green" />
          <div className="text-sm font-semibold text-gray-500">Quote</div>
          <div
            className="overflow-hidden text-sm font-normal text-gray-600 cursor-pointer text-nowrap overflow-ellipsis"
          >
            {quoteFileName}
          </div>
          <div
            className='text-sm font-semibold cursor-pointer text-primary-green hover:text-primary-green-700'
          >
            View
          </div>
        </a>
      )}
      {/* Pick Ticket */}
      {pickTicketLink && (
        <a
          className="flex items-center justify-start gap-2 py-2"
          href={pickTicketLink}
          target="_blank"
        >
          <CheckCircleIcon className="w-6 h-6 text-primary-green" />
          <div className="text-sm font-semibold text-gray-500">Pick Ticket</div>
          <div
            className="overflow-hidden text-sm font-normal text-gray-600 cursor-pointer text-nowrap overflow-ellipsis"
          >
            {pickTicketFileName}
          </div>
          <div
            className='text-sm font-semibold cursor-pointer text-primary-green hover:text-primary-green-700'
          >
            View
          </div>
        </a>
      )}
    </div>
    {additionalAttachments?.length > 0 && (
      <div
        className='w-1/2'
      >
        <div
          className='py-2 text-sm font-medium text-gray-500'
        >
          Additional Attachments
        </div>
        <div
          className='divide-y divide-gray-300'
        >
          {additionalAttachments.map((file, i) => (
            <div
              className='flex flex-row items-center justify-between'
            >
              <a
                key={i}
                className="flex items-center justify-start gap-2 px-2 py-2"
                href={file.getObjectSignedUrl}
                target="_blank"
              >
                <div
                  className="text-sm font-normal text-gray-600 cursor-pointer"
                >
                  {decodeURIComponent(file.getObjectSignedUrl.split('?')[0].split('/')?.pop() || "")}
                </div>
                <div
                  className='text-sm font-semibold cursor-pointer text-primary-green hover:text-primary-green-700'
                >
                  View
                </div>
              </a>
              <div
                className='px-2'
                onClick={() => {
                  deleteAdditionalAttachment(file)
                }}
              >
                <TrashIcon className='w-5 h-5 text-red-700 cursor-pointer hover:text-red-500' />
              </div>
            </div>
          ))}
        </div>
      </div>
    )}
  </Card>
}

/**
 * Modal for viewing images in full screen.
 * Displays a single image.
 * 
 * @param image - S3File object representing the image to display
 * @param open - boolean representing whether the modal is open
 * @param setOpen - function to set the open state of the modal
 */
function ViewImageModal({ image, open, setOpen }) {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
    >
      <div
        className='flex items-center justify-end w-full'
      >
        <button
          onClick={() => setOpen(false)}
          className='pb-4'
        >
          <XMarkIcon className='w-6 h-6 text-gray-500 hover:text-gray-700' />
        </button>
      </div>
      <img
        src={image?.getObjectSignedUrl}
        className='w-full'
      />
    </Modal>
  )
}

/**
 * Displays a single image in the Photos section.
 */
function ImageDisplay({ image, deletable, onImageDelete, onImageClick }) {
  const currentUser = useCurrentUser();

  return <div
    className='relative flex items-center justify-center bg-gray-200 rounded-md cursor-pointer h-60'
    onClick={() => onImageClick(image)}
  >
    <img
      src={image.getObjectSignedUrl}
      className='object-cover h-60 aspect-[4/3] rounded-md'
    />
    <div
      className={classNames(
        deletable ? "" : "hidden",
      )}
    >
      <div
        className='absolute bottom-0 right-0 items-center justify-center bg-gray-100 opacity-75 h-9 w-9 rounded-tl-md rounded-br-md'
      />
      <button
        onClick={() => onImageDelete(image)}
        className='absolute bottom-1.5 right-1.5 cursor-pointer'
      >
        <TrashIcon className='w-6 h-6 text-red-700 hover:text-red-500' />
      </button>
    </div>
  </div>
}

/**
  * Displays warehouse, delivery, installer verification, and other photos.
  */
function Photos({
  packagingImages,
  deliveryImages,
  installerVerificationImages,
  otherImages
}) {
  const [viewImageModalOpen, setViewImageModalOpen] = useState(false);
  const [viewingImage, setViewingImage] = useState<S3File | null>(null);

  const currentUser = useCurrentUser();

  return <Card>
    <ViewImageModal
      image={viewingImage}
      open={viewImageModalOpen}
      setOpen={setViewImageModalOpen}
    />
    <CardTitle>Photos</CardTitle>
    <div>
      {/* Packaging Images */}
      {packagingImages?.length > 0 && (
        <div>
          <div
            className='w-full py-2 my-3 border-b border-gray-300'
          >
            Packaging Verification Photos
          </div>
          <div
            className='grid grid-cols-3 gap-3'
          >
            {packagingImages.map((img, i) => (
              <ImageDisplay
                key={i}
                image={img}
                deletable={currentUser?.company?.type === "distributor"}
                onImageDelete={(image) => {
                  // TODO: implement delete functionality
                }}
                onImageClick={(image) => {
                  setViewingImage(image)
                  setViewImageModalOpen(true)
                }}
              />
            ))}
          </div>
        </div>
      )}
      {/* Delivery Images */}
      {deliveryImages?.length > 0 && (
        <div>
          <div
            className='w-full py-2 my-3 border-b border-gray-300'
          >
            Delivery Verification Photos
          </div>
          <div
            className='grid grid-cols-3 gap-3'
          >
            {deliveryImages.map((img, i) => (
              <ImageDisplay
                key={i}
                image={img}
                deletable={currentUser?.company?.type === "distributor"}
                onImageDelete={(image) => {
                  // TODO: implement delete functionality
                }}
                onImageClick={(image) => {
                  setViewingImage(image)
                  setViewImageModalOpen(true)
                }}
              />
            ))}
          </div>
        </div>
      )}
      {/* Installer Verification Images */}
      {installerVerificationImages?.length > 0 && (
        <div>
          <div
            className='w-full py-2 my-3 border-b border-gray-300'
          >
            Installer Verification Photos
          </div>
          <div
            className='grid grid-cols-3 gap-3'
          >
            {installerVerificationImages.map((img, i) => (
              <ImageDisplay
                key={i}
                image={img}
                deletable={currentUser?.company?.type === "installer"}
                onImageDelete={(image) => {
                  // TODO: implement delete functionality
                }}
                onImageClick={(image) => {
                  setViewingImage(image)
                  setViewImageModalOpen(true)
                }}
              />
            ))}
          </div>
        </div>
      )}
      {/* Other Images */}
      {/* TODO: need to adjust filepath convention for other photos */}
      {otherImages?.length > 0 && (
        <div>
          <div
            className='w-full py-2 my-3 border-b border-gray-300'
          >
            Other Photos
          </div>
          <div
            className='grid grid-cols-3 gap-3'
          >
            {otherImages.map((img, i) => (
              <ImageDisplay
                key={i}
                image={img}
                deletable={currentUser?.company?.type === "distributor"}
                onImageDelete={(image) => {
                  // TODO: implement delete functionality
                }}
                onImageClick={(image) => {
                  setViewingImage(image)
                  setViewImageModalOpen(true)
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  </Card>
}

/**
  * Displays a timeline of notes for the order.
  */
function Notes({ }) {
  const { order, setOrder } = useContext<OrderDetailsContextType>(OrderDetailsContext);

  const [showModal, setShowModal] = useState<boolean>(false);

  /**
   * Adds the note to the order's notes array.
   */
  function handleNoteAdd(note) {
    if (!setOrder) return;

    setOrder((prev) => {
      if (prev) {
        return {
          ...prev,
          notes: [
            note,
            ...prev.notes,
          ]
        }
      }
      return prev
    })
  }

  return <>
    <Card>
      <CardTitle>
        <div className="flex justify-between items-center">
          <p>Notes ({order.notes?.length ?? 0})</p>
          <Button
            variant="secondary"
            onClick={() => setShowModal(true)}
          >
            <div className="flex items-center gap-1 -mx-2">
              <PlusCircleIcon className="w-6 h-6 text-primary-green" />
              <p>Add Note</p>
            </div>
          </Button>
        </div>
      </CardTitle>
      <OrderNotes order={order} />
    </Card>
    <AddNoteModal
      order={order}
      open={showModal}
      setOpen={setShowModal}
      onNoteAdded={handleNoteAdd}
    />
  </>
}

/**
  * Displays a timeline of activity for the order.
  */
function Activity({ }) {
  const { order } = useContext<OrderDetailsContextType>(OrderDetailsContext);
  return <Card>
    <CardTitle>Activity</CardTitle>
    <OrderTimeline hideOrderLink orders={[order]} />
  </Card>
}

/**
  * Handles styling for card titles throughout the page.
  */
function CardTitle({ children }) {
  return <h2 className="pb-3 text-base font-semibold">{children}</h2>
}

// TODO: edit functionality for properties? callback prop?
/**
  * Displays a single property in a grid format.
  * Each property takes up 2 columns: 1 for the label, 1 for the value.
  * Handles styling.
  */
function Property({ label, value, className = "", icon = null }) {
  return <div
    className={classNames(
      "grid items-center justify-center col-span-2 py-2 grid-cols-subgrid",
      className
    )}
  >
    <div
      className='flex flex-row items-center justify-start gap-2'
    >
      {icon && icon}
      {label}
    </div>
    <div className="font-bold">{value}</div>
  </div>
}
